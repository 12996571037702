$c-primary: #3e23cd;
$c-secandary: #3e23cd;
$dots: false;
$progress-bar-overided: #ec5b67;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #3e23cd;
$loading-bg-overrided: #3e23cd;
$dot-overrided: #ec5b67;
$base-font-family: 'Poppins', sans-serif;
$font-family-heading: 'NotoSerif', sans-serif;

      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-Bold.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-BoldItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-SemiBold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-SemiBoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$btn-text: white;
@if variable-exists(btn-text-overrided) {
  $btn-text: $btn-text-overrided;
}

.success-flowv2-screen {
  .feedback-link-container-success {
    position: absolute;
    width: 100%;
    z-index: 10000;
  }

  .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 1.5rem 1.5rem 6rem 1.5rem;
    background: var(--page-background-color);
    z-index: 1000;

    .img-container {
      margin-bottom: 2.5rem; //40
      img {
        max-width: 100%;
        height: 3.375rem; //54
        object-fit: contain;
      }
    }

    .heading {
      font-family: var(--heading-font-family) !important;
      color: var(--heading-text-color) !important;
      text-align: left;
      font-weight: 600;
      font-size: var(--heading-font-size) !important;
      line-height: 1.1;
      letter-spacing: -0.03125rem; //-0.5px
      padding-bottom: 40px;
    }

    .subheading {
      font-family: var(--heading-font-family) !important;
      color: var(--heading-text-color) !important;
      font-weight: 600;
      font-size: 1.2rem;
    }

    .title {
      font-size: 1rem;
      margin-top: 1.5rem; //24
    }

    .footer {
      text-align: center;
    }

    .button {
      display: inline-block;
      justify-content: center;
      width: 50%;
      height: 2.8125rem; //45
      border: 1px solid var(--primary);
      background: var(--primary);
      font-family: var(--base-font-family);
      color: $btn-text;
      font-size: 1rem; //16
      border-radius: 0.625rem; //10
      margin-top: 1.5rem; //24
      padding: 0.125rem 1rem 0.25rem; //2 16 4
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      user-select: none;
      font-weight: 500;
    }
  }
}

$c-primary: #3e23cd;
$c-secandary: #3e23cd;
$dots: false;
$progress-bar-overided: #ec5b67;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #3e23cd;
$loading-bg-overrided: #3e23cd;
$dot-overrided: #ec5b67;
$base-font-family: 'Poppins', sans-serif;
$font-family-heading: 'NotoSerif', sans-serif;

      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-Bold.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-BoldItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NotoSerif';
        src: url('/assets-mobile/fonts/NotoSerif-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-SemiBold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-SemiBoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  @media all and (min-width: 500px) {
    text-align: center;
  }
  .title {
    margin-bottom: 25px;
    font-weight: 500;
  }

  label {
    @media all and (min-width: 500px) {
      display: block;
    }
  }

  .input {
    &.halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
